import {createTheme} from "@mui/material";


export const theme = createTheme({

    palette: {
        primary: {
            main: "rgb(0,100,50)"
        },
        secondary: {
            main: "#333"
        }
    },

    typography: {
        allVariants: {
            fontFamily: ""
        }
    }
})