import "./DevelopersList.css";
import {notify} from "../../../Utils/Notify";
import {useEffect, useState} from "react";
import {useTitle} from "../../../Utils/useTitle";
import {Breadcrumbs, Button, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {NavLink, useNavigate} from "react-router-dom";
import {DeveloperModel} from "../../../Models/DeveloperModel";
import {developerService} from "../../../Services/DeveloperService";
import {DevelopersCard} from "../DevelopersCard/DevelopersCard";


export function DevelopersList(): JSX.Element {

    const user = useSelector<AppState, UserModel>(store => store.user)
    const navigate = useNavigate()

    useTitle("Developers List")

    const [developers, setDevelopers] = useState<DeveloperModel[]>([]);

    useEffect(() => {

        developerService.getAllDevelopers()
            .then(dbPosts=> setDevelopers(dbPosts))
            .catch(err => notify.error(err))

    }, [])



    return (
        <div className="DevelopersList">
            <div className="Category-header">
                <div className="Category-name">
                    <h1>All Posts</h1>
                </div>
                <Breadcrumbs aria-label="breadcrumb" color='fffff'>
                    <NavLink to="/home" className="Navigate">HOME</NavLink>
                    <Typography color="#ffffff">DEVELOPERS</Typography>
                </Breadcrumbs>
                <div className="Category-button">
                    <NavLink to="/posts/new">{user && user.is_staff && <Button variant="contained" startIcon={<AddIcon />}>Add New Developer</Button> }</NavLink>
                </div>
            </div>
            <div className="grid">
                {developers?.map(d => <DevelopersCard key={d.id} developer={d} />)}
            </div>
        </div>
    );
}
