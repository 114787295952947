import "./Register.css";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {userService} from "../../../Services/UserService";
import {notify} from "../../../Utils/Notify";
import {UserModel} from "../../../Models/UserModel";

export function Register(): JSX.Element {

    const { register, handleSubmit } = useForm<UserModel>();
    const navigate = useNavigate();

    async function send(user: UserModel) {
        try {
            await userService.register(user);
            notify.success("User created successfully!")
            navigate("/login")
        }
        catch (err:any) {
            notify.error(err)
        }
    }


    return (
        <div className="Register">
            <h3>Register</h3>
            <form onSubmit={handleSubmit(send)}>

                <label>First name: </label>
                <input type="text" {...register("first_name")}/>

                <label>Last name: </label>
                <input type="text" {...register("last_name")}/>

                <label>Email: </label>
                <input type="email" {...register("email")}/>

                <label>Password: </label>
                <input type="password" {...register("password")}/>

                <button>Register</button>

            </form>

        </div>
    );
}
