import {GameModel} from "../Models/GameModel";
import {configureStore, createSlice} from "@reduxjs/toolkit";
import {
    add,
    update,
    remove,
    init,
    login,
    register,
    logout,
    addPost,
    updatePost,
    removePost,
    initPost,
    addGenre,
    updateGenre,
    removeGenre,
    initGenre,
    addDeveloper,
    updateDeveloper,
    removeDeveloper,
    initDeveloper, addPublisher, updatePublisher, removePublisher, initPublisher
} from "./reducers";
import {UserModel} from "../Models/UserModel";
import {PostModel} from "../Models/PostModel";
import {GenreModel} from "../Models/GenreModel";
import {DeveloperModel} from "../Models/DeveloperModel";
import {PublisherModel} from "../Models/PublisherModel";


export type AppState = {
    games: GameModel[];
    user: UserModel;
    posts: PostModel[];
    genres: GenreModel[];
    developers: DeveloperModel[];
    publishers: PublisherModel[];
}
// Games slice
const gamesSlice = createSlice({
    name: "games",
    initialState: [],
    reducers: {add, update, remove, init}
});

// User slice
const userSlice = createSlice({
    name: "user",
    initialState: null,
    reducers: { login, register, logout }
})

// Post slice
const postsSlice = createSlice({
    name: "posts",
    initialState: [],
    reducers: { addPost, updatePost, removePost, initPost }
})

// Genre slice
const genresSlice = createSlice({
    name: "genres",
    initialState: [],
    reducers: { addGenre, updateGenre, removeGenre, initGenre }
})

// Developer slice
const developersSlice = createSlice({
    name: "developers",
    initialState: [],
    reducers: { addDeveloper, updateDeveloper, removeDeveloper, initDeveloper }
})

// Publisher slice
const publishersSlice = createSlice({
    name: "publishers",
    initialState: [],
    reducers: { addPublisher, updatePublisher, removePublisher, initPublisher }
})


export const gamesActions = gamesSlice.actions;
export const userActions = userSlice.actions;
export const postsActions = postsSlice.actions;
export const genresActions = genresSlice.actions;
export const developersActions = developersSlice.actions;
export const publishersActions = publishersSlice.actions;

export const store = configureStore<AppState>({
    reducer: {
        games: gamesSlice.reducer,
        user: userSlice.reducer,
        posts: postsSlice.reducer,
        genres: genresSlice.reducer,
        developers: developersSlice.reducer,
        publishers: publishersSlice.reducer,
    }
});

