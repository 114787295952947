import "./EditGame.css";
import {useForm} from "react-hook-form";
import {GameModel} from "../../../Models/GameModel";
import {useNavigate, useParams} from "react-router-dom";
import {gamesService} from "../../../Services/GamesService";
import {notify} from "../../../Utils/Notify";
import {useEffect} from "react";
import {useTitle} from "../../../Utils/useTitle";

export function EditGame(): JSX.Element {


    useTitle("Edit Game")
    const { register, handleSubmit, setValue } = useForm<GameModel>()
    const navigate = useNavigate();
    const params = useParams();
    const id = +params.id;

    useEffect(() => {
        gamesService.getOneGame(id)
            .then(dbGame => {
                setValue("name", dbGame.name);
                setValue("summary", dbGame.summary);
                setValue("release_date", dbGame.release_date)
                setValue("image", dbGame.image)
            })
            .catch(err => notify.error(err))
    }, []);

    async function send(game: GameModel) {
        try {
            game.id = id;
            await gamesService.updateGame(game);
            notify.success("Game updated successfully");
            navigate("/games");
        }
        catch (err: any) {
            notify.error(err)
        }
    }


    return (
        <div className="EditGame">

            <form onSubmit={handleSubmit(send)}>
                <label>Game Name: </label>
                <br/>
                <input type="text" {...register("name")}/>
                <br/>
                <label>Game Summary: </label>
                <br/>
                <input type="text" {...register("summary")}/>
                <br/>
                <label>Game Date: </label>
                <br/>
                <input type="date" {...register("release_date")}/>
                <br/>
                <label>Game Image Link: </label>
                <br/>
                <input type="text" {...register("image")}/>

                <button>Update</button>

            </form>
        </div>
    );
}
