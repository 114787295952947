import "./ContactUs.css";
import {useTitle} from "../../../Utils/useTitle";
import {
    Button,
    ButtonGroup,
    Checkbox, FormControl,
    FormControlLabel,
    InputLabel, MenuItem, Select, SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {useState} from "react";

export function ContactUs(): JSX.Element {

    useTitle("Contact us")

    return (
        <div className="ContactUs">

            <Typography variant="h4">Contact Us</Typography>

            <form>

                <TextField label="Full Name" variant="standard" className="textBox"/>

                <TextField type="email" label="Email" variant="standard" className="textBox"/>

                <TextField
                    label="Message"
                    variant="standard"
                    multiline
                    maxRows={4}
                    className="textBox"
                />

                <FormControl fullWidth variant="standard">
                    <InputLabel>Contact Us Reason</InputLabel>
                    <Select
                        label="Contact Us Reason" defaultValue={0}>
                        <MenuItem selected disabled value={0}>Select one...</MenuItem>
                        <MenuItem value={1}>Feedback 💬</MenuItem>
                        <MenuItem value={2}>Need help ❗</MenuItem>
                        <MenuItem value={3}>Report Bug 🐞</MenuItem>
                        <MenuItem value={4}>Other ❓</MenuItem>
                    </Select>
                </FormControl>
                
                <FormControlLabel control={<Checkbox />} label="Send me promotional Emails" />

                <ButtonGroup fullWidth color="secondary" variant="contained">
                    <Button>Send</Button>
                    <Button type="reset">Clear</Button>
                </ButtonGroup>

            </form>


        </div>
    );
}
