import "./GamesList.css";
import {gamesService} from "../../../Services/GamesService";
import {notify} from "../../../Utils/Notify";
import {useEffect, useState} from "react";
import {GameModel} from "../../../Models/GameModel";
import {GameCard} from "../GameCard/GameCard";
import {useTitle} from "../../../Utils/useTitle";
import {Breadcrumbs, Button, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {NavLink, useNavigate} from "react-router-dom";


export function GamesList(): JSX.Element {

    const user = useSelector<AppState, UserModel>(store => store.user)
    const navigate = useNavigate()

    useTitle("Games List")

    const [games, setGames] = useState<GameModel[]>([]);

    useEffect(() => {

        gamesService.getALlGames()
            .then(dbGames=> setGames(dbGames))
            .catch(err => notify.error(err))

    }, [])

    // const sortedGames = games.sort((a, b) => a.id - b.id);

    return (
        <div className="GamesList">
            <div className="Category-header">
                <div className="Category-name">
                    <h1>All Games</h1>
                </div>
                <Breadcrumbs aria-label="breadcrumb" color='fffff'>
                    <NavLink to="/home" className="Navigate">HOME</NavLink>
                    <Typography color="#ffffff">GAMES</Typography>
                </Breadcrumbs>
                <div className="Category-button">
                    <NavLink to="/games/new">{user && user.is_staff && <Button variant="contained" startIcon={<AddIcon />}>Add New Game</Button> }</NavLink>
                </div>
            </div>
            <div className="grid">
                {games?.map(g => <GameCard key={g.id} game={g} />)}
            </div>
        </div>
    );
}
