import "./About.css";
import {useTitle} from "../../../Utils/useTitle";

export function About(): JSX.Element {

    useTitle("About Page")

    return (
        <div className="About">
			About...
        </div>
    );
}
