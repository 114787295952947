import { Link } from "react-router-dom";
import "./Header.css";
import { UserMenu } from "../../UserArea/UserMenu/UserMenu";
import {SearchBar} from "../../SearchArea/SearchBar/SearchBar";

export function Header(): JSX.Element {
    return (
        <header className="Header">
            <div className="Header-inner">
                <Link to="/home" className="logo-link">
                    <div className="logo">
                        <h1>JB Gaming</h1>
                    </div>
                </Link>
                <SearchBar />
                <UserMenu />
            </div>
        </header>
    );
}
