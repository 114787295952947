import "./Home.css";
import {notify} from "../../../Utils/Notify";
import sourceLogo1 from "../../../Assets/Images/Logo.png"
import {useEffect} from "react";
import {useTitle} from "../../../Utils/useTitle";

export function Home(): JSX.Element {

    useTitle("Home Page")

    return (
        <div className="Home">
            {/*<img src={sourceLogo1}/>*/}
            <h1>Welcome to JB Gaming Website!</h1>
            <p className="p-header">Our website is your go-to hub for everything related to gaming. Here, you'll find an
                extensive database
                of games, detailed reviews, the latest news, and engaging posts from our team and users. We strive to
                provide you with the most up-to-date and interesting information so that you're always in the loop about
                the latest gaming releases and events.</p><br/>
            <h3>Extensive Game Database</h3>
            <p className="p-text">Our database includes thousands of games from various genres and platforms. Each game
                is accompanied by a
                detailed description, screenshots, video reviews, and user ratings. Whether you're looking for
                information on new releases or classic games, you'll find everything you need to make an informed
                choice. We regularly update our database to include the latest games and expansions.</p>
            <h3>Detailed Reviews and Ratings</h3>
            <p className="p-text">We take pride in the quality of our reviews. Our team of experienced gaming
                journalists tests games to
                provide you with honest and objective feedback. We consider all aspects of a game: graphics, sound,
                gameplay, storyline, and multiplayer mode. Additionally, we provide comprehensive ratings so you can
                quickly understand how well a game might suit your preferences.</p>
            <h3>Latest News</h3>
            <p className="p-text">Stay up-to-date with all the news from the gaming world through our news section. We
                cover all important events: from new game announcements and updates to major tournaments and expos. Our
                news will keep you informed about all the changes and innovations in the industry.</p>
            <h3>Engaging Posts</h3>
            <p className="p-text">Our users also contribute by creating engaging posts and articles. You can find tips
                and tricks, walkthrough guides, opinions, and discussions on gaming events. We encourage active
                participation from our audience, so you can always share your thoughts and experiences.</p>
            <h3>Community</h3>
            <p className="p-text">We create a space where gamers can share experiences and interact. On our forum and in
                the comments section, you can find like-minded people, ask questions, and discuss your favorite games.
                We believe that games bring people together, and we strive to create a friendly and supportive
                community.</p>
            <h3>User Support</h3>
            <p className="p-text">If you have any questions or issues, our support team is always ready to help. We
                value our users and aim to provide you with the best possible experience on our website.</p>
            <p className="p-text">Join us and dive into the world of gaming with our site! Whether you're a hardcore
                gamer or just starting your journey in the gaming world, you'll find everything you need here for an
                unforgettable gaming experience.</p>
        </div>
    );
}
