import "./Layout.css";
import {Header} from "../Header/Header";
import {Copyrights} from "../Copyrights/Copyrights";
import {Menu} from "../Menu/Menu";
import {Routing} from "../Routing/Routing";
import {SearchBar} from "../../SearchArea/SearchBar/SearchBar";

export function Layout(): JSX.Element {
    return (
        <div className="Layout">

            <header>
                <Header />
            </header>

            <aside>
                <Menu />
            </aside>

            <main>
                <Routing />
            </main>

            <footer>
                <Copyrights />
            </footer>

        </div>
    );
}
