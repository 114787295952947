import {PostModel} from "../Models/PostModel";
import axios from "axios";
import {appConfig} from "../Utils/AppConfig";
import {postsActions, store} from "../Redux/state";
import {notify} from "../Utils/Notify";

class PostsService {
    public async getAllPosts(): Promise<PostModel[]> {
        // if(store.getState().posts.length > 0) {
        //     return store.getState().posts;
        // }
        const response = await axios.get<PostModel[]>(appConfig.postsUrl);
        const posts = response.data;
        store.dispatch(postsActions.initPost(posts));
        return posts;
    }

}
export const postsService = new PostsService();