import {GameModel} from "../Models/GameModel";
import {PayloadAction} from "@reduxjs/toolkit";
import {UserModel} from "../Models/UserModel";
import {PostModel} from '../Models/PostModel';
import {GenreModel} from "../Models/GenreModel";
import {DeveloperModel} from "../Models/DeveloperModel";
import {PublisherModel} from "../Models/PublisherModel";


export function add(currentState: GameModel[], action: PayloadAction<GameModel>): GameModel[] {
    const newState = [...currentState]
    const gameToAdd =  action.payload;
    newState.push(gameToAdd);
    return newState;
}

export function update(currentState: GameModel[], action: PayloadAction<GameModel>): GameModel[] {
    const newState = [...currentState]
    const gameToUpdate =  action.payload;
    const index = newState.findIndex(g => g.id === gameToUpdate.id);
    newState[index] = gameToUpdate;
    return newState;
}

export function remove(currentState: GameModel[], action: PayloadAction<number>): GameModel[] {
    const newState = [...currentState]
    const gameIdToDelete =  action.payload;
    const index = newState.findIndex(g => g.id === gameIdToDelete);
    newState.splice(index, 1);
    return newState;
}
export function init(currentState: GameModel[], action: PayloadAction<GameModel[]>): GameModel[] {
    const allGames = action.payload;
    return allGames;
}
// ------------------------------------------------------------------------------------------------
// Auth reducers

export function login(currentState: UserModel, action: PayloadAction<UserModel>): UserModel {
    const newState = action.payload;
    return newState;
}

export function register(currentState: UserModel, action: PayloadAction<UserModel>): UserModel {
    const newState = action.payload;
    return newState;
}

export function logout(currentState: UserModel, action: PayloadAction): UserModel {
    return null;
}

// ------------------------------------------------------------------------------------------------
// Posts reducers

export function addPost(currentState: PostModel[], action: PayloadAction<PostModel>): PostModel[] {
    const newState = [...currentState]
    const postToAdd =  action.payload;
    newState.push(postToAdd);
    return newState;
}

export function updatePost(currentState: PostModel[], action: PayloadAction<PostModel>): PostModel[] {
    const newState = [...currentState]
    const postToUpdate =  action.payload;
    const index = newState.findIndex(g => g.id === postToUpdate.id);
    newState[index] = postToUpdate;
    return newState;
}

export function removePost(currentState: PostModel[], action: PayloadAction<number>): PostModel[] {
    const newState = [...currentState]
    const postIdToDelete =  action.payload;
    const index = newState.findIndex(g => g.id === postIdToDelete);
    newState.splice(index, 1);
    return newState;
}
export function initPost(currentState: PostModel[], action: PayloadAction<PostModel[]>): PostModel[] {
    const allPosts = action.payload;
    return allPosts;
}

// ------------------------------------------------------------------------------------------------
// Genres reducers

export function addGenre(currentState: GenreModel[], action: PayloadAction<GenreModel>): GenreModel[] {
    const newState = [...currentState]
    const genreToAdd =  action.payload;
    newState.push(genreToAdd);
    return newState;
}

export function updateGenre(currentState: GenreModel[], action: PayloadAction<GenreModel>): GenreModel[] {
    const newState = [...currentState]
    const genreToUpdate =  action.payload;
    const index = newState.findIndex(g => g.id === genreToUpdate.id);
    newState[index] = genreToUpdate;
    return newState;
}

export function removeGenre(currentState: GenreModel[], action: PayloadAction<number>): GenreModel[] {
    const newState = [...currentState]
    const genreIdToDelete =  action.payload;
    const index = newState.findIndex(g => g.id === genreIdToDelete);
    newState.splice(index, 1);
    return newState;
}
export function initGenre(currentState: GenreModel[], action: PayloadAction<GenreModel[]>): GenreModel[] {
    const allGenres = action.payload;
    return allGenres;
}

// ------------------------------------------------------------------------------------------------
// Developer reducers

export function addDeveloper(currentState: DeveloperModel[], action: PayloadAction<DeveloperModel>): DeveloperModel[] {
    const newState = [...currentState]
    const developerToAdd =  action.payload;
    newState.push(developerToAdd);
    return newState;
}

export function updateDeveloper(currentState: DeveloperModel[], action: PayloadAction<DeveloperModel>): DeveloperModel[] {
    const newState = [...currentState]
    const developerToUpdate =  action.payload;
    const index = newState.findIndex(d => d.id === developerToUpdate.id);
    newState[index] = developerToUpdate;
    return newState;
}

export function removeDeveloper(currentState: DeveloperModel[], action: PayloadAction<number>): DeveloperModel[] {
    const newState = [...currentState]
    const developerIdToDelete =  action.payload;
    const index = newState.findIndex(d => d.id === developerIdToDelete);
    newState.splice(index, 1);
    return newState;
}
export function initDeveloper(currentState: DeveloperModel[], action: PayloadAction<DeveloperModel[]>): DeveloperModel[] {
    const allDevelopers = action.payload;
    return allDevelopers;
}

// ------------------------------------------------------------------------------------------------
// Publisher reducers

export function addPublisher(currentState: PublisherModel[], action: PayloadAction<PublisherModel>): PublisherModel[] {
    const newState = [...currentState]
    const publisherModelToAdd =  action.payload;
    newState.push(publisherModelToAdd);
    return newState;
}

export function updatePublisher(currentState: PublisherModel[], action: PayloadAction<PublisherModel>): PublisherModel[] {
    const newState = [...currentState]
    const publisherToUpdate =  action.payload;
    const index = newState.findIndex(p => p.id === publisherToUpdate.id);
    newState[index] = publisherToUpdate;
    return newState;
}

export function removePublisher(currentState: PublisherModel[], action: PayloadAction<number>): PublisherModel[] {
    const newState = [...currentState]
    const publisherIdToDelete =  action.payload;
    const index = newState.findIndex(p => p.id === publisherIdToDelete);
    newState.splice(index, 1);
    return newState;
}
export function initPublisher(currentState: PublisherModel[], action: PayloadAction<PublisherModel[]>): PublisherModel[] {
    const allPublishers = action.payload;
    return allPublishers;
}