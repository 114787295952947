import "./PostsList.css";
import {postsService} from "../../../Services/PostService";
import {notify} from "../../../Utils/Notify";
import {useEffect, useState} from "react";
import {useTitle    } from "../../../Utils/useTitle";
import {Breadcrumbs, Button, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {NavLink, useNavigate} from "react-router-dom";
import {PostCard} from "../PostCard/PostCard";
import {PostModel} from "../../../Models/PostModel";
import {gamesService} from "../../../Services/GamesService";


export function PostsList(): JSX.Element {

    const user = useSelector<AppState, UserModel>(store => store.user)
    const navigate = useNavigate()

    useTitle("Posts List")

    const [posts, setPosts] = useState<PostModel[]>([]);

    useEffect(() => {

        postsService.getAllPosts()
            .then(dbPosts=> setPosts(dbPosts))
            .catch(err => notify.error(err))

    }, [])


    return (
        <div className="PostsList">
            <div className="Category-header">
                <div className="Category-name">
                    <h1>All Posts</h1>
                </div>
                <Breadcrumbs aria-label="breadcrumb" color='fffff'>
                    <NavLink to="/home" className="Navigate">HOME</NavLink>
                    <Typography color="#ffffff">POSTS</Typography>
                </Breadcrumbs>
                <div className="Category-button">
                    <NavLink to="/posts/new">{user && user.is_staff && <Button variant="contained" startIcon={<AddIcon />}>Add New Post</Button> }</NavLink>
                </div>
            </div>
            <div className="grid">
                {posts?.map(p => <PostCard key={p.id} post={p} />)}
            </div>
        </div>
    );
}
