import "./UserMenu.css";
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {NavLink, useNavigate} from "react-router-dom";
import {userService} from "../../../Services/UserService";
import 'bootstrap/dist/css/bootstrap.min.css';
import {notify} from "../../../Utils/Notify";

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LoginIcon from '@mui/icons-material/Login';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import {useState} from "react";

export function UserMenu(): JSX.Element {

    const user = useSelector<AppState, UserModel>(store => store.user)
    const navigate = useNavigate()

    function logout() {
        handleClose()
        userService.logout();
        notify.success("Logout Success")
        navigate("/home")
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div className="UserMenu">
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Account Menu">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 1,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 43,
                                height: 43,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 16,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,

                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {user && user.is_staff &&
                        <MenuItem onClick={handleClose}>
                            <Avatar/> <span className="blink">{user.first_name} {user.last_name}</span>
                        </MenuItem>
                    }
                    {user && !user.is_staff &&
                        <MenuItem onClick={handleClose}>
                            <Avatar /> {user.first_name} {user.last_name}
                        </MenuItem>
                    }
                    {!user &&
                        <MenuItem onClick={handleClose}>
                            <Avatar /> Hello Guest
                        </MenuItem>
                    }
                    <Divider />
                    {user && user.is_staff &&
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <AdminPanelSettingsIcon fontSize="small" />
                            </ListItemIcon>
                            Admin Panel
                        </MenuItem>
                    }
                    {user &&
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                    }
                    {user &&
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                    }
                    {!user &&
                        <NavLink to="/login" className='MenuItemDecor'><MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <LoginIcon fontSize="small" />
                            </ListItemIcon>
                            Sign In
                        </MenuItem></NavLink>
                    }
                    {!user &&
                        <NavLink to="/register" className='MenuItemDecor'><MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <HowToRegIcon fontSize="small" />
                            </ListItemIcon>
                            Sign Up
                        </MenuItem></NavLink>
                    }
                </Menu>

            </>
        </div>
    );
}
