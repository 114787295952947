    import "./GameCard.css";
    import {GameModel} from "../../../Models/GameModel";
    import {notify} from "../../../Utils/Notify";
    import {useNavigate} from "react-router-dom"
    import {useEffect, useState} from "react";
    import {GameGenreModel} from "../../../Models/GameGenreModel";
    import {gamesService} from "../../../Services/GamesService";
    import Divider from "@mui/material/Divider";
    import {List, ListItem, ListItemText} from "@mui/material";

    type GameCardProps = {
        game: GameModel;
    }

    export function GameCard(props: GameCardProps): JSX.Element {

        const [isHovered, setIsHovered] = useState(false);
        const navigate = useNavigate();
        const [genre, setGenre] = useState<[GameGenreModel]>();

        useEffect(() => {
            gamesService.getGameGenres(props.game.id)
                .then(dbGenre => setGenre(dbGenre))
                .catch(err => notify.error(err))

        }, []);



        function showDetails(): void {
            //  notify.success(`The id of that games is: ${props.game.id}`)
            navigate("/games/details/" + props.game.id)
        }

        const style = {
            py: 0,
            width: "100%",
            maxWidth: 360,
            borderRadius: 3,
            border: "1px solid",
            borderColor: "divider",
            backgroundColor: "#ffffff",
        };

        return (
            <div
                className="game-card"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={showDetails}
            >
                <div className="game-card-image">
                    <img src={props.game.image} alt={props.game.name} />
                </div>
                <div className="game-card-content">
                    <div className="game-card-title">{props.game.name}</div>
                    {isHovered && (
                        <div className="game-card-details">
                            <div className="game-card-genres">
                                <span className="left-align">Genres:</span>
                                <span className="right-align">{genre?.map(gen => gen.name).join(", ")}</span>
                            </div>
                            <Divider sx={style}/>
                            <div className="game-card-release-date">
                                <span className="left-align">Release Date:</span>
                                <span className="right-align">{props.game.release_date}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }