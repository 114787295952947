import "./GenresList.css";
import {notify} from "../../../Utils/Notify";
import {useEffect, useState} from "react";
import {useTitle} from "../../../Utils/useTitle";
import {Breadcrumbs, Button, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {NavLink, useNavigate} from "react-router-dom";
import {GenreModel} from "../../../Models/GenreModel";
import {genreService} from "../../../Services/GenreService";
import {GenresCard} from "../GenresCard/GenresCard";


export function GenresList(): JSX.Element {

    const user = useSelector<AppState, UserModel>(store => store.user)
    const navigate = useNavigate()

    useTitle("Genres List")

    const [genres, setGenres] = useState<GenreModel[]>([]);

    useEffect(() => {

        genreService.getAllGenres()
            .then(dbPosts=> setGenres(dbPosts))
            .catch(err => notify.error(err))

    }, [])



    return (
        <div className="PostsList">
            <div className="Category-header">
                <div className="Category-name">
                    <h1>All Posts</h1>
                </div>
                <Breadcrumbs aria-label="breadcrumb" color='fffff'>
                    <NavLink to="/home" className="Navigate">HOME</NavLink>
                    <Typography color="#ffffff">GENRES</Typography>
                </Breadcrumbs>
                <div className="Category-button">
                    <NavLink to="/posts/new">{user && user.is_staff && <Button variant="contained" startIcon={<AddIcon />}>Add New Genre</Button> }</NavLink>
                </div>
            </div>
            <div className="grid">
                {genres?.map(g => <GenresCard key={g.id} genre={g} />)}
            </div>
        </div>
    );
}
