import React, { useState, ChangeEvent } from "react";
import "./SearchBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

interface SearchResult {
    id: number;
    name: string;
    category: "Games" | "Users";
}

export function SearchBar(): JSX.Element {
    const [query, setQuery] = useState<string>("");
    const [results, setResults] = useState<SearchResult[]>([]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setQuery(value);

        // Имитируем поиск и получение результатов
        if (value) {
            setResults([
                { id: 1, name: "Game 1", category: "Games" },
                { id: 2, name: "Game 2", category: "Games" },
                { id: 3, name: "User 1", category: "Users" },
                { id: 4, name: "User 2", category: "Users" },
            ]);
        } else {
            setResults([]);
        }
    };

    const handleClear = (): void => {
        setQuery("");
        setResults([]);
    };

    return (
        <div className="search-bar">
            <div className="search-input-wrapper">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                    type="text"
                    value={query}
                    onChange={handleInputChange}
                    placeholder="Search 868,780 games"
                />
                {query && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="clear-icon"
                        onClick={handleClear}
                    />
                )}
            </div>
            {results.length > 0 && (
                <div className="search-results">
                    <h4>Games</h4>
                    {results
                        .filter((result) => result.category === "Games")
                        .map((result) => (
                            <div key={result.id} className="search-result">
                                {result.name}
                            </div>
                        ))}
                    <h4>Users</h4>
                    {results
                        .filter((result) => result.category === "Users")
                        .map((result) => (
                            <div key={result.id} className="search-result">
                                {result.name}
                            </div>
                        ))}
                    <div className="see-all-results">See all results</div>
                </div>
            )}
        </div>
    );
}
