import {useForm} from "react-hook-form"
import "./Login.css";
import {CredentialsModel} from "../../../Models/CredentialsModel";
import {userService} from "../../../Services/UserService";
import {notify} from "../../../Utils/Notify";
import {useNavigate} from "react-router-dom";

export function Login(): JSX.Element {

    const { register, handleSubmit } = useForm<CredentialsModel>();
    const navigate = useNavigate();

    async function send(credentials: CredentialsModel) {
        try {
            await userService.login(credentials);
            notify.success("Welcome Back!")
            navigate("/home")
        }
        catch (err: any) {
            notify.error(err)
        }
    }

    return (
        <div className="Login">
            <h3>Login</h3>
            <form onSubmit={handleSubmit(send)}>
                <label>Email: </label>
                <br/>
                <input type="email" {...register("username")}/>
                <br/>
                <label>Password: </label>
                <br/>
                <input type="password" {...register("password")}/>

                <button>Login</button>
            </form>

        </div>
    );
}
