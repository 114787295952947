import {GameModel} from "../Models/GameModel";
import axios from "axios";
import {appConfig} from "../Utils/AppConfig";
import {gamesActions, store} from "../Redux/state";
import {notify} from "../Utils/Notify";
import {GameDetailsModel} from "../Models/GameDetailsModel";
import {GameDeveloperModel} from "../Models/GameDeveloperModel";
import {GamePublisherModel} from "../Models/GamePublisherModel";
import {GameGenreModel} from "../Models/GameGenreModel";
import {GamePostModel} from "../Models/GamePostModel";

class GamesService {
	public async getALlGames(): Promise<GameModel[]> {
        if(store.getState().games.length > 0) {
            return store.getState().games;
        }
        const response = await axios.get<GameModel[]>(appConfig.gamesUrl);
        const games = response.data;
        store.dispatch(gamesActions.init(games));
        return games;
    }

    public async getOneGame(id: number): Promise<GameModel> {
        const desiredGame = store.getState().games.find(g => g.id === id)
        if(desiredGame) return desiredGame;
        const response = await axios.get<GameModel>(appConfig.gamesUrl + id + "/");
        const game = response.data;
        return game;
    }

    public async getDetailGame(id: number): Promise<GameDetailsModel> {
        const response = await axios.get<GameDetailsModel>(appConfig.gamesUrl + id + "/");
        const gamedetails = response.data;
        return gamedetails;
    }

    public async getGamePublishers(id: number): Promise<[GamePublisherModel]> {
        const response = await axios.get<[GamePublisherModel]>(appConfig.gamesUrl + id + "/publishers/");
        const publisher = response.data;
        return publisher;
    }

    public async getGameGenres(id: number): Promise<[GameGenreModel]> {
        const response = await axios.get<[GameGenreModel]>(appConfig.gamesUrl + id + "/genres/");
        const genre = response.data;
        return genre;
    }

    public async getGamePosts(id: number): Promise<[GamePostModel]> {
        const response = await axios.get<[GamePostModel]>(appConfig.gamesUrl + id + "/posts/");
        const post = response.data;
        return post;
    }

    public async getGameDevelopers(id: number): Promise<[GameDeveloperModel]> {
        const response = await axios.get<[GameDeveloperModel]>(appConfig.gamesUrl + id + "/developers/");
        const developer = response.data;
        return developer;
    }

    public async addGame(game: GameModel): Promise<void> {
        const token = localStorage.getItem("access");

        // If no token - do nothing:
        if (!token) {
            notify.error("You not have access to add games");
            return;
        }
        const response = await axios.post<GameModel>(appConfig.gamesUrl, game, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const dbGame = response.data;

        store.dispatch(gamesActions.add(dbGame))


    }

    public async updateGame(game: GameModel): Promise<void> {
        const token = localStorage.getItem("access");

        // If no token - do nothing:
        if (!token) {
            notify.error("You not have access to add games");
            return;
        }

        const response = await axios.put<GameModel>(appConfig.gamesUrl + game.id + "/", game, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
        const dbGame = response.data;

        store.dispatch(gamesActions.update(dbGame))
    }

    public async deleteGame(id: number): Promise<void> {
        const token = localStorage.getItem("access");

        // If no token - do nothing:
        if (!token) {
            notify.error("You not have access to add games");
            return;
        }
        await axios.delete<GameModel>(appConfig.gamesUrl + id + "/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        store.dispatch(gamesActions.remove(id));
    }

}
export const gamesService = new GamesService();
