import "./PageNotFound.css";
import error1 from "../../../Assets/Images/404.png";
import {useTitle} from "../../../Utils/useTitle";

export function PageNotFound(): JSX.Element {

    useTitle("Page Not Found")

    return (
        <div className="PageNotFound">
            <img src={error1}/>
        </div>
    );
}
