import "./Menu.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStar, faFire, faForward, faCalendarAlt, faTrophy, faCrown, faGamepad, faStore, faFolderOpen, faChevronDown, faWandSparkles} from '@fortawesome/free-solid-svg-icons';
import { TotalGames } from "../../GamesArea/TotalGames/TotalGames";
import { useSelector } from "react-redux";
import { AppState } from "../../../Redux/state";
import { UserModel } from "../../../Models/UserModel";

export function Menu(): JSX.Element {
    const user = useSelector<AppState, UserModel | null>(store => store.user);

    return (
        <nav className="Menu">
            <NavLink to="/home">
                <FontAwesomeIcon icon={faHome} /> Home
            </NavLink>


            <NavLink to="/games">
                <FontAwesomeIcon icon={faWandSparkles} /> Games
            </NavLink>

            <NavLink to="/genres">
                <FontAwesomeIcon icon={faFire} /> Genres
            </NavLink>

            <NavLink to="/developers">
                <FontAwesomeIcon icon={faStar} /> Developers
            </NavLink>

            <NavLink to="/publishers">
                <FontAwesomeIcon icon={faStar} /> Publishers
            </NavLink>

            <NavLink to="/posts">
                <FontAwesomeIcon icon={faStar} /> Posts
            </NavLink>

            <TotalGames/>

        </nav>
    );
}
