import {GenreModel} from "../Models/GenreModel";
import axios from "axios";
import {appConfig} from "../Utils/AppConfig";
import {genresActions, store} from "../Redux/state";
import {notify} from "../Utils/Notify";

class GenresService {
    public async getAllGenres(): Promise<GenreModel[]> {
        if(store.getState().genres.length > 0) {
            return store.getState().genres;
        }
        const response = await axios.get<GenreModel[]>(appConfig.genresUrl);
        const genres = response.data;
        store.dispatch(genresActions.initGenre(genres));
        return genres;
    }

}
export const genreService = new GenresService();