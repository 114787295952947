import {DeveloperModel} from "../Models/DeveloperModel";
import axios from "axios";
import {appConfig} from "../Utils/AppConfig";
import {developersActions, store} from "../Redux/state";
import {notify} from "../Utils/Notify";

class DevelopersService {
    public async getAllDevelopers(): Promise<DeveloperModel[]> {
        if(store.getState().developers.length > 0) {
            return store.getState().developers;
        }
        const response = await axios.get<DeveloperModel[]>(appConfig.developersUrl);
        const developers = response.data;
        store.dispatch(developersActions.initDeveloper(developers));
        return developers;
    }

}
export const developerService = new DevelopersService();