    import axios from "axios";
    import { CredentialsModel } from "../Models/CredentialsModel";
    import { UserModel } from "../Models/UserModel";
    import { appConfig } from "../Utils/AppConfig";
    import { store, userActions } from "../Redux/state";
    import {jwtDecode} from "jwt-decode";
    import {notify} from "../Utils/Notify";

    class UserService {

        public constructor() {
            this.init();
        }

        private async init() {
            // Extract token from local storage if exists:
            const token = localStorage.getItem("access");

            // If no token - do nothing:
            if (!token) return;

            // We have a token - decode it:
            const dbUser = await axios.get<UserModel>(appConfig.userDetailsUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Create action object containing the logged-in user:
            const action = userActions.login(dbUser.data);

            // Save logged-in user in global state:
            store.dispatch(action);
        }


        public async login(credentials: CredentialsModel): Promise<void> {
            try {
                // Send credentials to backend:
                const response = await axios.post<{ access: string, refresh: string, user: UserModel }>(appConfig.loginUrl, credentials);

                // Extract tokens and user from axios response:
                const accessToken = response.data.access;
                const refreshToken = response.data.refresh;
                const dbUser = response.data.user;

                // Dispatch user login action to Redux store:
                const action = userActions.login(dbUser);
                store.dispatch(action);

                // Save tokens to local storage:
                localStorage.setItem("access", accessToken);
                localStorage.setItem("refresh", refreshToken);

                this.init()

            } catch (error) {
                console.error("Login failed:", error);
                throw error;
            }
        }

        public async register(user: UserModel): Promise<void> {
            try {
                const response = await axios.post<UserModel>(appConfig.registerUrl, user);
                const dbUser = response.data;
                const action = userActions.register(dbUser);
                store.dispatch(action);
            } catch (err: any) {
                notify.error(err)
            }
        }

        public logout(): void {
            // Create action object for logout:
            const action = userActions.logout();

            // Logout user from global state:
            store.dispatch(action);

            // Remove tokens from local storage:
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
        }

    }

    export const userService = new UserService();
