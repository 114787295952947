class AppConfig {
	public readonly gamesUrl = "http://127.0.0.1:8000/api/thegamer/games/";
	public readonly loginUrl = "http://127.0.0.1:8000/api/thegamer/auth/login"
	public readonly registerUrl = "http://127.0.0.1:8000/api/thegamer/auth/signup"
	public readonly userDetailsUrl = "http://127.0.0.1:8000/api/thegamer/auth/me"
	public readonly postsUrl = "http://127.0.0.1:8000/api/thegamer/posts/"
	public readonly genresUrl = "http://127.0.0.1:8000/api/thegamer/genres/"
	public readonly developersUrl = "http://127.0.0.1:8000/api/thegamer/developers/"
	public readonly publisherUrl = "http://127.0.0.1:8000/api/thegamer/publishers/"

}

export const appConfig = new AppConfig();
