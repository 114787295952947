import "./DevelopersCard.css";
import {DeveloperModel} from "../../../Models/DeveloperModel";
import {notify} from "../../../Utils/Notify";
import {useNavigate} from "react-router-dom"
import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";

type DevelopersCardProps = {
    developer: DeveloperModel;
}

export function DevelopersCard(props: DevelopersCardProps): JSX.Element {

    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();



    function showDetails(): void {
        //  notify.success(`The id of that games is: ${props.game.id}`)
        navigate("/developers/details/" + props.developer.id)
    }

    const style = {
        py: 0,
        width: "100%",
        maxWidth: 360,
        borderRadius: 3,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "#ffffff",
    };


    return (
        <div
            className={`developer-card ${isHovered ? "hovered" : ""}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={showDetails}
        >
            <div className="developer-card-content">
                <div className="developer-card-title">{props.developer.name}</div>
                {isHovered && (
                    <div className="developer-card-details">
                        <div className="developer-card-writer">
                            <span className="label">developer:</span>
                            <span className="value">{props.developer.name}</span>
                        </div>
                        <Divider className="divider" />
                    </div>
                )}
            </div>
        </div>
    );
}