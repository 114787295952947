import "./GamesDetails.css";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GameDetailsModel} from "../../../Models/GameDetailsModel";
import {gamesService} from "../../../Services/GamesService";
import {notify} from "../../../Utils/Notify";
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {Breadcrumbs, Button, ButtonGroup, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {GameDeveloperModel} from "../../../Models/GameDeveloperModel";
import {GamePublisherModel} from "../../../Models/GamePublisherModel";
import {GameGenreModel} from "../../../Models/GameGenreModel";
import {GamePostModel} from "../../../Models/GamePostModel";


export function GamesDetails(): JSX.Element {

    const user = useSelector<AppState, UserModel>(store => store.user)
    const [game, setGame] = useState<GameDetailsModel>();
    const [developer, setDeveloper] = useState<GameDeveloperModel[]>();
    const [publisher, setPublisher] = useState<GamePublisherModel[]>();
    const [genre, setGenre] = useState<GameGenreModel[]>();
    const [post, setPost] = useState<GamePostModel[]>();
    const params = useParams();
    const id = +params.id;
    const navigate = useNavigate();

    document.title = game? game.name : "Game Detail";

    useEffect(() => {

        gamesService.getDetailGame(id)
            .then(dbGame => setGame(dbGame))
            .catch(err => notify.error(err))

        gamesService.getGameDevelopers(id)
            .then(dbDeveloper => setDeveloper(dbDeveloper))
            .catch(err => notify.error(err))

        gamesService.getGamePosts(id)
            .then(dbPost => setPost(dbPost))
            .catch(err => notify.error(err))

        gamesService.getGamePublishers(id)
            .then(dbPublisher => setPublisher(dbPublisher))
            .catch(err => notify.error(err))

        gamesService.getGameGenres(id)
            .then(dbGenre => setGenre(dbGenre))
            .catch(err => notify.error(err))

    }, [])


    async function deleteMe() {
        try {
            const sure = window.confirm("Are you sure?");
            if (!sure) return;
            await gamesService.deleteGame(id);
            notify.success("Game Deleted")
            navigate("/games")
        } catch (err: any) {
        }
    }


    return (
        <div className="GamesDetails">
            <Breadcrumbs aria-label="breadcrumb" color='fffff'>
                <NavLink to="/home" className="Navigator">HOME</NavLink>
                <NavLink to="/games" className="Navigator">GAMES</NavLink>
                <Typography color="#B0B0B0">{game?.name}</Typography>
            </Breadcrumbs>
            {game &&
                <div className="game-detail">
                    <div className="image-container">
                        <img src={game?.image} alt={game?.name} className="image"/>
                    </div>
                    <div className="info-container">
                        <h1 className="title">{game?.name}</h1>
                        <p className="date">{game?.release_date}</p>
                        <p className="summary">{game?.summary}</p>
                        <div>
                            <p>Developers: </p>
                            {developer?.map((dev) => (
                                <p key={dev.id}>{dev.name}</p>))}
                        </div>
                        <div>
                            <p>Publishers: </p>
                            {publisher?.map((pub) => (
                                <p key={pub.id}>{pub.name}</p>))}
                        </div>
                            <p>Genres: </p>
                            {genre?.map(gen => gen.name).join(', ')}
                        <div>
                            <p>Posts: </p>
                            {post?.map(pos => (
                                <NavLink key={pos.id} to={`/posts/${pos.id}`}>{pos.title} </NavLink>
                            ))}
                        </div>
                    </div>
                </div>
            }

            <br/><br/>
            {!user && <NavLink to="/games"><Button variant="contained" startIcon={<ArrowBackIcon />}>Back</Button></NavLink>}

            {user && user.is_staff && (
                <>
                    <ButtonGroup variant="contained" aria-label="Basic button group">
                        <NavLink to="/games"><Button variant="contained" startIcon={<ArrowBackIcon />}>Back</Button></NavLink>
                        <NavLink to={`/games/edit/${game?.id}`}>{user && user.is_staff && <Button variant="contained" startIcon={<EditIcon />} color="info">Edit</Button> }</NavLink>
                        <NavLink to="/games/new" onClick={deleteMe}>{user && user.is_staff && <Button variant="contained" startIcon={<DeleteIcon />} color="error">Delete</Button> }</NavLink>
                    </ButtonGroup>
                </>
            )}

        </div>
    );
}
