import "./TotalGames.css";
import {useEffect, useState} from "react";
import {AppState, store} from "../../../Redux/state";
import {useSelector} from "react-redux";

export function TotalGames(): JSX.Element {

    const count = useSelector<AppState, number>(appState => appState.games.length);

    // const [count, setCount] = useState<number>();
    //
    // useEffect(() => {
    //
    //     const unsubscribe = store.subscribe(() => {
    //         setCount(store.getState().games.length)
    //     });
    //
    //     return () => {
    //         unsubscribe();
    //     }
    //
    // }, []);

    return (
        <div className="TotalGames">
            <span>Total Games: </span>
            <span>{count}</span>
            <span>🎮</span>
        </div>
    );
}
