import "./Routing.css";
import {Route, Routes, Navigate} from "react-router-dom";
import {Home} from "../../HomeArea/Home/Home";
import {GamesList} from "../../GamesArea/GamesList/GamesList";
import {About} from "../../AboutArea/About/About";
import {PageNotFound} from "../PageNotFound/PageNotFound";
import {ContactUs} from "../../AboutArea/ContactUs/ContactUs";
import {GamesDetails} from "../../GamesArea/GamesDetails/GamesDetails";
import {AddGame} from "../../GamesArea/AddGame/AddGame";
import {EditGame} from "../../GamesArea/EditGame/EditGame";
import {Login} from "../../UserArea/Login/Login";
import {Register} from "../../UserArea/Register/Register";
import {PostsList} from "../../PostsArea/PostsList/PostsList";
import {GenresList} from "../../GenresArea/GenresList/GenresList";
import {DevelopersList} from "../../DevelopersArea/DevelopersList/DevelopersList";
import {PublishersList} from "../../PublishersArea/PublishersList/PublishersList";

export function Routing(): JSX.Element {
    return (
        <div className="Routing">

            <Routes>

                <Route path="/login" element={<Login />} />

                <Route path="/register" element={<Register />} />

                <Route path="/home" element={<Home />} />

                <Route path="/games" element={<GamesList />} />

                <Route path="/games/details/:id" element={<GamesDetails />} />

                <Route path="/games/new" element={<AddGame />} />

                <Route path="/games/edit/:id" element={<EditGame />} />

                <Route path="/posts" element={<PostsList />} />

                <Route path="/genres" element={<GenresList />} />

                <Route path="/developers" element={<DevelopersList />} />

                <Route path="/publishers" element={<PublishersList />} />

                <Route path="/about" element={<About />} />

                <Route path="/contact-us" element={<ContactUs />} />

                <Route path="/" element={<Navigate to="/home" />} />

                <Route path="*" element={<PageNotFound />} />

            </Routes>

        </div>
    );
}
