import "./GenresCard.css";
import {GenreModel} from "../../../Models/GenreModel";
import {notify} from "../../../Utils/Notify";
import {useNavigate} from "react-router-dom"
import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";

type GenreCardProps = {
    genre: GenreModel;
}

export function GenresCard(props: GenreCardProps): JSX.Element {

    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();



    function showDetails(): void {
        //  notify.success(`The id of that games is: ${props.game.id}`)
        navigate("/genres/details/" + props.genre.id)
    }

    const style = {
        py: 0,
        width: "100%",
        maxWidth: 360,
        borderRadius: 3,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "#ffffff",
    };


    return (
        <div
            className={`genre-card ${isHovered ? "hovered" : ""}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={showDetails}
        >
            <div className="genre-card-content">
                <div className="genre-card-title">{props.genre.name}</div>
                {isHovered && (
                    <div className="genre-card-details">
                        <div className="genre-card-writer">
                            <span className="label">Genre:</span>
                            <span className="value">{props.genre.name}</span>
                        </div>
                        <Divider className="divider" />
                    </div>
                )}
            </div>
        </div>
    );
}