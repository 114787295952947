import "./PostCard.css";
import {PostModel} from "../../../Models/PostModel";
import {notify} from "../../../Utils/Notify";
import {useNavigate} from "react-router-dom"
import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";
import {gamesService} from "../../../Services/GamesService";
import {GameModel} from "../../../Models/GameModel";

type PostCardProps = {
    post: PostModel;
}

export function PostCard(props: PostCardProps): JSX.Element {

    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const [game, setGame] = useState<GameModel>();


    useEffect(() => {
        if (Array.isArray(props.post.games) && props.post.games.length > 0) {
            // @ts-ignore
            gamesService.getOneGame(props.post.games[0])
                .then(dbGame => setGame(dbGame))
                .catch(err => notify.error(err));
        }
    }, [props.post.games]);


    function showDetails(): void {
        //  notify.success(`The id of that games is: ${props.game.id}`)
        navigate("/posts/details/" + props.post.id)
    }

    const style = {
        py: 0,
        width: "100%",
        maxWidth: 360,
        borderRadius: 3,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "#ffffff",
    };


    return (
        <div
            className={`post-card ${isHovered ? "hovered" : ""}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={showDetails}
        >
            <div className="post-card-content">
                <div className="post-card-title">{props.post.title}</div>
                {isHovered && (
                    <div className="post-card-details">
                        <div className="post-card-writer">
                            <span className="label">Writer:</span>
                            <span className="value">{props.post.writer}</span>
                        </div>
                        <Divider className="divider" />
                        <div className="post-card-release-date">
                            <span className="label">Date:</span>
                            <span className="value">{props.post.release_date}</span>
                        </div>
                        <Divider className="divider" />
                        <div className="post-card-release-date">
                            <span className="label">Game:</span>
                            <span className="value">{game.name}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}