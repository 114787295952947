import "./PublishersList.css";
import {notify} from "../../../Utils/Notify";
import {useEffect, useState} from "react";
import {useTitle} from "../../../Utils/useTitle";
import {Breadcrumbs, Button, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {NavLink, useNavigate} from "react-router-dom";
import {PublisherModel} from "../../../Models/PublisherModel";
import {publisherService} from "../../../Services/PublisherService";
import {PublishersCard} from "../PublishersCard/PublishersCard";


export function PublishersList(): JSX.Element {

    const user = useSelector<AppState, UserModel>(store => store.user)
    const navigate = useNavigate()

    useTitle("Publishers List")

    const [publishers, setPublishers] = useState<PublisherModel[]>([]);

    useEffect(() => {

        publisherService.getAllPublishers()
            .then(dbPosts=> setPublishers(dbPosts))
            .catch(err => notify.error(err))

    }, [])



    return (
        <div className="PublishersList">
            <div className="Category-header">
                <div className="Category-name">
                    <h1>All Posts</h1>
                </div>
                <Breadcrumbs aria-label="breadcrumb" color='fffff'>
                    <NavLink to="/home" className="Navigate">HOME</NavLink>
                    <Typography color="#ffffff">PUBLISHERS</Typography>
                </Breadcrumbs>
                <div className="Category-button">
                    <NavLink to="/posts/new">{user && user.is_staff && <Button variant="contained" startIcon={<AddIcon />}>Add New Publisher</Button> }</NavLink>
                </div>
            </div>
            <div className="grid">
                {publishers?.map(p => <PublishersCard key={p.id} publisher={p} />)}
            </div>
        </div>
    );
}
