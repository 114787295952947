import "./AddGame.css";
import {GameModel} from "../../../Models/GameModel";
import {useForm} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {notify} from "../../../Utils/Notify";
import {gamesService} from "../../../Services/GamesService";
import {useTitle} from "../../../Utils/useTitle";
import {useSelector} from "react-redux";
import {AppState} from "../../../Redux/state";
import {UserModel} from "../../../Models/UserModel";
import {useEffect} from "react";

export function AddGame(): JSX.Element {

    useTitle("Add Game")
    const { register, handleSubmit } = useForm<GameModel>()
    const navigate = useNavigate();

    async function send(game: GameModel) {
        try {
            await gamesService.addGame(game);
            notify.success("Game added successfully");
            navigate("/games");
        }
        catch (err: any) {
            notify.error(err)
        }
    }

    const user = useSelector<AppState, UserModel>(store => store.user)
    useEffect(() => {
        if(!user.is_staff) {
            notify.error("You have not permission!")
            navigate("/home");
            return;
        }
        if(!user) {
            notify.error("You are not logged in!")
            navigate("/login");
            return;
        }
    }, []);

    return (
        <div className="AddGame">

            <form onSubmit={handleSubmit(send)}>
                <label>Game Name: </label>
                <br/>
                <input type="text" {...register("name")}/>
                <br/>
                <label>Game Summary: </label>
                <br/>
                <input type="text" {...register("summary")}/>
                <br/>
                <label>Game Date: </label>
                <br/>
                <input type="date" {...register("release_date")}/>
                <br/>
                <label>Game Image Link: </label>
                <br/>
                <input type="text" {...register("image")}/>

                <button>Add</button>

            </form>

        </div>
    );
}
