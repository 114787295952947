import {PublisherModel} from "../Models/PublisherModel";
import axios from "axios";
import {appConfig} from "../Utils/AppConfig";
import {publishersActions, store} from "../Redux/state";
import {notify} from "../Utils/Notify";

class PublishersService {
    public async getAllPublishers(): Promise<PublisherModel[]> {
        if(store.getState().publishers.length > 0) {
            return store.getState().publishers;
        }
        const response = await axios.get<PublisherModel[]>(appConfig.publisherUrl);
        const publishers = response.data;
        store.dispatch(publishersActions.initPublisher(publishers));
        return publishers;
    }

}
export const publisherService = new PublishersService();